<template>
    <div class="view-root flex-column">
        <div style="display: flex;justify-content: space-between;align-items: center;">
            <p class="common-title">会议手册</p>
            <p class="btn-wrap-width" @click="editHandbook(null)">新增手册</p>
        </div>
        <div class="table-margin-top flex-1-overflow-y">
            <div class="table-header table-margin-bottom">
                <div class="table-index">序号</div>
                <div class="table-name">手册名称</div>
                <div class="table-content">手册内容</div>
                <div class="table-operation">操作</div>
            </div>
            <div v-for="(handbook, index) in dataList" :key="index" class="table-item table-margin-bottom">
                <div class="table-index">{{ (index + 1) }}</div>
                <div class="table-name" style="font-weight: bold;">{{ handbook.title }}</div>
                <div class="table-content" v-html="handbook.content"></div>
                <div class="table-operation" style="display: flex;">
                    <p class="list-btn-blue" style="text-align: left;padding-left: 8px;"
                        @click="editHandbook(handbook)">
                        编辑</p>
                    <p class="list-btn-red" style="margin-left: 10px;text-align: left;padding-left: 8px;"
                        @click="doRemoveHandbook(handbook)">删除</p>
                </div>
            </div>
        </div>
        <el-dialog :visible.sync="showHandbookDialog" :title="handbook.id == '' ? '添加手册' : '编辑手册'" width="65%">
            <div>
                <el-form :model="handbook" ref="handbookForm" :rules="rules" label-position="left">
                    <el-form-item label="手册名称" prop="title">
                        <el-input v-model="handbook.title" placeholder="请输入"></el-input>
                    </el-form-item>
                    <el-form-item label="" prop="content">
                        <label>手册内容</label>
                        <quill-editor class="editor" style="margin-bottom:20px" ref="myTextEditor"
                            v-model="handbook.content" :options="editorOption">
                        </quill-editor>
                    </el-form-item>
                </el-form>
                <div style="text-align: right;">
                    <span slot="footer" class="dialog-footer">
                        <el-button @click="showHandbookDialog = false">取 消</el-button>
                        <el-button type="primary" @click="saveHandbook()">确 定</el-button>
                    </span>
                </div>
            </div>
        </el-dialog>

    </div>
</template>
<script>
import {
    updateMeetingHandbook,
    getMeetingHandbooks
} from '../../api/api'
import '../../assets/common/common.css'
export default ({
    name: 'index',
    data() {
        return {
            meeting_id: this.$route.query.meeting_id,
            dataList: [],
            handbook: {
                id: '',
                meeting_id: '',
                title: '',
                content: ''
            },
            editorOption: {
                modules: {
                    toolbar: [
                        ['bold', 'italic', 'underline', 'strike'], // 加粗 斜体 下划线 删除线
                        ['blockquote', 'code-block'], // 引用  代码块
                        [{ header: 1 }, { header: 2 }], // 1、2 级标题
                        [{ list: 'ordered' }, { list: 'bullet' }], // 有序、无序列表
                        [{ script: 'sub' }, { script: 'super' }], // 上标/下标
                        [{ indent: '-1' }, { indent: '+1' }], // 缩进
                        // [{'direction': 'rtl'}],                         // 文本方向
                        [{ size: ['small', false, 'large', 'huge'] }], // 字体大小
                        [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
                        [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
                        [{ font: [] }], // 字体种类
                        [{ align: [] }], // 对齐方式
                        ['clean'], // 清除文本格式
                        ['link', 'image', 'video'] // 链接、图片、视频
                    ] // 工具菜单栏配置
                },
                placeholder: '', // 提示
                readyOnly: false, // 是否只读
                theme: 'snow', // 主题 snow/bubble
                syntax: true // 语法检测
            },
            rules: {
                title: [
                    { required: true, message: 'Please input', trigger: 'blur' }
                ],
                content: [
                    { required: true, message: 'Please input', trigger: 'blur' }
                ],
            },
            showHandbookDialog: false
        }
    },
    mounted() {
        this.fetchData()
    },
    methods: {
        fetchData() {
            getMeetingHandbooks(this.meeting_id).then((res) => {
                this.dataList = res.data.data
            })
        },
        doRemoveHandbook(row) {
            this.$message.success('即将开放')
        },
        editHandbook(row) {
            if (row) {
                this.handbook = {
                    id: row._id.$id,
                    meeting_id: row.meeting_id,
                    title: row.title,
                    content: row.content
                }
            } else {
                this.handbook = {
                    id: '',
                    meeting_id: this.meeting_id,
                    title: '',
                    content: ''
                }
            }
            this.showHandbookDialog = true
        },
        saveHandbook() {
            this.$refs['handbookForm'].validate((val) => {
                if (val) {
                    updateMeetingHandbook(this.handbook).then((res) => {
                        this.$message.success('保存成功')
                        this.fetchData()
                        this.showHandbookDialog = false
                    })
                }
            })
        }
    }
})
</script>
<style>
.ql-container {
    height: 200px !important;
}
</style>
<style scoped lang="scss">
.table-name {
    flex: 2;
}

.table-content {
    flex: 5;
}

.table-operation {
    flex: 1.5;
}

.ql-container {
    height: 400px !important;
}
</style>